/* ================================ */
/* Products  */
/* ================================ */


.productContainer {
    @apply flex flex-col gap-[30px] mb-3 md:mt-0 mt-3;
}

.productInsideContainerFrist {
    @apply md:flex hidden items-center justify-center md:gap-[40px] gap-3;
}



.sectionContainer {
    @apply flex ml-2 mr-2 pr-0 md:gap-[46px] gap-[21px] md:pt-[46px];
}

.productBox {
    @apply relative md:w-[298px] w-[120px] md:h-[295px] h-[120px] bg-cover object-fill transition duration-300 ease-in-out hover:shadow-lg dark:hover:shadow-black/30;
}

.productBoxInside {
    @apply absolute md:bottom-5 bottom-1 left-[50%] right-[50%] flex flex-col items-center gap-[80px];
}

.TextBox {
    @apply bg-[#FFFFFF]/70 rounded-[5px] md:w-[264px] w-[110px] md:p-3 p-1 h-fit md:mt-[162px] mt-[80px];
}

.pro-name-text {
    @apply justify-center uppercase tracking-wide md:text-[18px] text-[12px] text-center font-roboto;
}


.bankDiscountContainer {
    @apply flex md:flex-row flex-col items-center gap-[14px] justify-center md:my-[25px] md:pl-0 pl-2 md:pr-0 pr-2;
}

.BankStipBox {
    @apply flex gap-2 md:w-[410px] w-full md:h-[68px] h-[50px] rounded-[5px] border-[1.5px] border-[#838383];
}

.imageBox1 {
    @apply flex items-center;
}

.imageBox2 {
    @apply flex justify-center items-center;
}

.imageBox3 {
    @apply flex items-center;
}

.sinhanBankImage {
    @apply md:w-[142.27px] w-[110px] h-[22.72px];
}

.wooriBankImage {
    @apply ml-[30px] md:w-[80px] w-[80px] md:h-[80px] h-[60px];
}

.hanaBankImage {
    @apply md:w-[142.27px] w-[110px] h-[22.72px];
}

.verticleLine {
    @apply w-[1.5px] md:h-[50px] h-[40px] bg-gray-500 mt-2;
}

.discounText {
    @apply uppercase md:text-[16px] text-[13px] font-medium font-roboto;
}

.creditCardText {
    @apply md:text-[14px] text-[10px] text-[#454545] font-roboto;
}

.T_C {
    @apply text-[#424242] text-[6px] text-end font-roboto;
}




/* ================================ */
/* Deals of the day product */
/* ================================ */



.DealContainer {
    @apply flex flex-col md:gap-[21px] gap-[12px] md:p-0 p-2;
}


.dealsInsideContainer {
    @apply flex justify-center;
}

.DealBanner {
    @apply md:w-[900px] w-[250px] md:h-[450px] h-[122px];
}

.dealBannerInsideContainer {
    @apply bg-[#F8F8F8] md:w-[554px] w-fit md:h-[450px] h-[122px];
}

.BoxCream {
    @apply flex flex-col justify-center items-center md:gap-[38px] gap-1 md:mt-[58px];
}

.cellcoveryCreamText {
    @apply md:text-4xl text-[11px] text-slate-900 text-center font-roboto;
}

.flatOff {
    @apply md:text-5xl text-[11px] brightness-105 font-bold tracking-wider text-center md:my-10 font-roboto;
}

.ImageBoxContaiiner {
    @apply flex flex-col justify-center items-center md:gap-[20px];
}

.InsideImageBox {
    @apply flex md:flex-row flex-col justify-center items-center md:gap-[53px];
}

.foxhubImage {
    @apply md:w-[155px] w-full md:h-[90px];
}

.line {
    @apply md:block hidden w-[2px] h-[41px] bg-gray-500;
}

.oracleImage {
    @apply md:w-[137px] md:h-[90px] text-center md:pt-3;
}

.shopText {
    @apply underline text-xl text-center font-roboto;
}


/* ================================ */
/* Shop by Categories */
/* ================================ */


/* In YourCSSFile.css */
.ShopContainer {
    @apply flex flex-wrap md:gap-7 gap-3 justify-center items-center;
}



.productImage {
    @apply relative md:w-[205px] w-[110px] md:h-[264px] h-[134px];
}

.image-product {
    @apply md:w-[205px] w-[110px] md:h-[264px] h-[134px];
}

.productImageTextBox {
    @apply flex flex-col items-center gap-[80px];
}

.shopProductTextBox {
    @apply absolute md:p-2 p-1 bottom-2 bg-[#A000A0]/70 rounded-[5px] md:w-[171px] w-[100px] md:h-[83px] h-fit;
}

.shopprodductName {
    @apply text-[#FFFFFF] font-medium md:text-[18px] text-[13px] text-center font-roboto;
}

.shopprodductOffrate {
    @apply text-[#FFFFFF] md:text-[22px] text-[12px] text-center font-roboto;
}

.shopNow {
    @apply md:text-[14px] text-[10px] font-medium text-[#FFFFFF] text-center font-roboto;
}


/* ================================ */
/* SkinCare Sales */
/* ================================ */


.skinCareBox {
    @apply bg-[#1F0A56] md:mt-[88px] mt-[30px];
}

.skinCareBoxInside {
    @apply flex flex-col md:gap-[21px] gap-[10px];
}



.skincareHeaderText {
    @apply text-white md:text-[40px] text-[23px] md:pt-[31px] pt-[10px] font-semibold md:pl-[130px] pl-[20px] font-roboto;
}

.skincareTextbox {
    @apply flex md:flex-row flex-col justify-center md:p-10 p-4;
}

.skincareTextBox {
    @apply flex flex-col items-start gap-[15px] md:w-[628px] w-full md:h-[337px] h-fit md:rounded-l-xl box-border bg-[#EDEDED];
}

.skinCareTextBox2 {
    @apply flex flex-col justify-center;
}

.skinCareMainHeaderText {
    @apply md:text-[40px] uppercase font-light md:pt-[76px] pt-[4px] md:pl-[45px] pl-[10px] font-roboto;
}

.skinCareShopNow {
    @apply md:w-[190px] md:pt-[70px] h-[28px];
}

.skinCareShopNowText {
    @apply underline md:text-[24px] text-[13px] font-bold pl-[45px] text-start font-roboto;
}

.skinCareImage {
    @apply md:w-[628px] w-fit md:h-[337px] h-fit;
}

.skinCareBoxTwo {
    @apply flex md:flex-row flex-col gap-[10px] justify-center pb-[33px];
}

.skinCareBoxTwoInside {
    @apply flex justify-evenly md:gap-[10px];
}

.skinCarelogoBox {
    @apply md:w-[200px] w-[170px] md:h-[96px] h-[40px] bg-[#FFFFFF] rounded-[100px] items-center flex justify-center;
}


/* ================================ */
/* You Might Also Like */
/* ================================ */



.alsoLikeContainer {
    @apply flex flex-col md:gap-[50px] gap-[25px] md:mt-[50px] mt-[25px] md:ml-[30px] md:mr-[30px];
}

.sloLikeContainerTwo {
    @apply flex flex-wrap md:gap-[50px] gap-[25px] justify-center;
}

.alsoLikeContainerTwoInside {
    @apply flex justify-evenly md:gap-[50px];
}

.asloLikeProductBox {
    @apply flex flex-col w-[167px] h-[199px] gap-[14px];
}

.alsoLikeImageBox {
    @apply w-[167px] h-[167px] rounded-full border-2 border-fuchsia-500;
}

.alsoLikeProductImage {
    @apply pt-[27px] pb-[26px] pl-[40px] pr-[40px];
}

.alsoLikeProductName {
    @apply text-[15px] text-center font-roboto;
}

/* ================================ */
/* More Product */
/* ================================ */


.moreProductBox {
    @apply md:my-[50px] my-[70px];
}

.moreProductInsideBox {
    @apply flex md:flex-row flex-col items-center justify-center md:pl-[133px] md:pr-[133px] md:gap-0 gap-[30px];
}

.moreProductsBox {
    @apply md:w-[282px] w-full h-[184px] flex flex-col md:justify-center items-center md:mt-0 mt-[30px];
}

.moreProductImage {
    @apply md:w-[222px] w-[370px] md:h-[121px] h-[170px] flex justify-center;
}

.moreProductTextBox {
    @apply pl-[13px] flex flex-col gap-[3px] border border-[#C1C1C1] md:w-[222px] w-[370px] rounded-b-xl;
}

.moreProductOffText {
    @apply uppercase font-medium text-[20px] font-roboto;
}

.moreProductDeetailText {
    @apply text-[14px] text-[#858585] font-roboto;
}

/* ================================ */
/* Best Seller */
/* ================================ */


.bestSellerContainer {
    @apply flex justify-center items-center md:gap-[31px] gap-[10px] p-2;
}

.bestSellerProductContainer {
    @apply flex md:gap-[31px] gap-3;
}

.bestSellerProductBox {
    @apply md:flex flex-col justify-center items-center md:w-[290px] md:h-fit md:pt-[30px];
}

.bestSellerImageBox {
    @apply md:w-full w-full md:h-[391px] bg-[#FFE4FF] rounded-tl-[40px] rounded-br-[40px] pt-[55px] flex justify-center;
}

.bestSellerImage1 {
    @apply w-[194px] h-[226px] md:p-0 p-4;
}

.bestSellerImage2 {
    @apply w-[243px] h-[226px] md:p-0 p-4;
}

.bestSellerImage3 {
    @apply md:w-[77px] w-fit md:h-[278px] h-[230px] md:p-0 p-4;
}

.bestSellerImage4 {
    @apply md:w-[246px] h-[230px] md:p-0 p-4;
}

.bestSellerEntityBox {
    @apply flex;
}

.bestSellerReviewText {
    @apply md:text-[10px] text-[8px] text-center pt-[8px] font-roboto;
}

.bestSellerProductName {
    @apply md:text-[18px] text-[14px] font-semibold font-roboto whitespace-nowrap overflow-hidden text-ellipsis md:mt-4 mt-2;
}

.bestSellerProductPrice {
    @apply md:text-[16px] font-semibold font-roboto;
}

.bestSellerProductPriceText {
    @apply text-[#8E8E8E] md:text-[12px] pt-[4px] font-roboto;
}

/* ================================ */
/* Featured Products */
/* ================================ */

.featuredHeadingText {
    @apply md:pl-[133px] pl-[10px] md:text-[40px] text-[25px] font-bold md:mt-[50px] mt-[15px] font-roboto;
}

.featuredProductContainer {
    @apply flex md:flex-row flex-col justify-center gap-[14px] md:mt-[15px] mt-[5px] md:p-0 p-2;
}

.featuredProductBoxInside {
    @apply flex justify-center;
}

.featuredProductInside {
    @apply flex flex-col justify-center;
}

.FeaturedProductImage {
    @apply md:w-[620px] w-full md:h-[299px] h-full;
}

.FeaturedProductImageText {
    @apply md:w-[620px] w-full md:h-[69px] h-full pl-[4px] border border-[#C1C1C1] rounded-b-xl space-y-[3px] pt-[4px] hover:bg-gray-200/50 duration-300;
}

.FeaturedProductPrice {
    @apply text-[20px] font-medium font-roboto;
}

.FeaturedProductPriceText {
    @apply text-[14px] text-[#858585] font-roboto;
}

/* ================================ */
/* Best Seller Auto Play carousel */
/* ================================ */

.carouselContainer {
    @apply mt-[45px] md:ml-[140px] md:mr-[140px];
}

.carouselHeadingText {
    @apply md:text-[40px] text-[25px] font-bold mb-3 font-roboto;
}

.carouselContainerInside {
    @apply flex md:flex-row flex-col justify-center items-center gap-[20px] pt-[33px];
}

.carouselProductContainer {
    @apply flex md:gap-[15px] gap-[8px];
}

.carouselProductMainBox {
    @apply w-[161px] h-[300px] flex flex-col gap-[5px];
}

.carouselProductBox {
    @apply md:w-[161px] h-[215px] border-[#E7E7E7] flex flex-col;
}

.product-image {
    @apply w-[72px] h-[178px] overflow-hidden transform hover:scale-110 transition-transform duration-300;
}


.carouselProductSpecificationBox {
    @apply flex gap-1 pr-1;
}

.productReview {
    @apply text-[#515151] text-[12px] font-medium;
}

.starEntity {
    @apply pt-[2px] text-[10px];
}

.productReviewInNumber {
    @apply pt-[2px] text-[#7F7272] text-[10px];
}

.productSpecification {
    @apply text-[#505050] md:text-[18px] text-[14px] font-medium mt-2 font-roboto overflow-hidden whitespace-nowrap text-ellipsis;
}

.productQuantityInML {
    @apply text-[#828282] text-[13px] font-roboto;
}

.productPrice {
    @apply text-[13px] font-normal font-roboto;
}

.productPrice2 {
    @apply text-[13px] text-[#828282] font-normal line-through font-roboto;
}

.productPrice3 {
    @apply text-[#528945] text-[13px] font-roboto;
}

/* ================================ */
/* carousel for mobile responsive */
/* ================================ */


.carouselContainerForMobile {
    @apply pl-2 my-5 md:hidden block
}

/* ================================ */
/* RECOMMENDED FOR YOU section */
/* ================================ */

.recomndedContainer {
    @apply md:my-[73px];
}

.recomndedHeading {
    @apply md:pl-[133px] pl-[20px] tracking-wide md:text-[40px] text-[25px] font-bold font-roboto;
}

.recomndedContainerInside {
    @apply flex md:flex-row flex-col items-center justify-center md:gap-[40px] gap-[10px] mt-[21px];
}

.recomndedProductBox {
    @apply flex justify-center object-fill md:w-[392px] w-full h-[268px] md:p-0 p-2;
}

.recomndedProductImage {
    @apply relative w-full h-full bg-cover object-fill rounded-xl;
}

.recomndedProductTextParent {
    @apply flex items-center justify-center;
}

.recomndedProductText {
    @apply absolute bottom-2 text-center text-[#FFFFFF] text-[16px] font-medium;
}

.recomdedProductName {
    @apply tracking-wide text-[22px] font-roboto;
}