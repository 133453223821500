.main_wrap {
    @apply mt-0;
}

.checkout_heading {
    @apply text-[26px] font-roboto font-bold pl-[100px] mt-[20px];
}

.main_child_wrap {
    @apply flex justify-center;
}

.child_wrap {
    @apply w-[1450px] h-[600px] bg-[#ECECEC] p-4 mt-[30px];
}

.boxes_main_wrap {
    @apply flex justify-evenly;
}

.main_input_box {
    @apply flex justify-between;
}

.child_box_frist {
    @apply w-[754px] h-fit bg-[#FFFFFF] p-5 mt-[10px] rounded;
}

.add_text {
    @apply text-[17px] font-roboto font-semibold;
}

.input_name {
    @apply outline-none w-[165px] h-[31px] border border-[#D6D6D6] rounded p-2 text-[10px];
}

.input_Pincode {
    @apply outline-none w-[352px] h-[31px] border border-[#D6D6D6] rounded p-2 text-[10px];
}

._input_box_frist {
    @apply flex flex-col gap-[30px] mt-[10px];
}

._input_box_frist_inside {
    @apply flex items-center gap-[20px];
}

._input_box_secend {
    @apply mt-[10px] flex flex-col gap-[30px];
}

.textarea_class {
    @apply outline-none w-full min-h-[72px] max-h-[102px] border border-[#D6D6D6] rounded p-2 text-[10px];
}

.character_length {
    @apply text-[10px] text-[#939292] font-roboto;
}

.button_box {
    @apply flex items-center gap-[60px] mt-[37px];
}

.button_switch {
    @apply text-[#565656] text-[14px] flex items-center gap-1 font-roboto font-medium;
}

.radio_input {
    @apply text-[#A000A0];
}

.line_horizental {
    @apply w-full h-[2px] bg-[#DBDBDB] mt-[36px];
}

._cancel_btn {
    @apply text-[#A000A0] text-[14px] font-roboto font-medium;
}

._save_btn {
    @apply text-[#FFFFFF] text-[15px] font-roboto font-bold bg-[#A000A0] w-[157px] h-[43px];
}

.btn_box_ {
    @apply flex gap-[56px] mt-[41px];
}

.main_btn_box {
    @apply flex items-center justify-end;
}

/* total payable box */

.total_Payable_main {
    @apply w-[373px] h-[390px] bg-[#FFFFFF] rounded mt-[10px];
}

.cart_wrap_child_two {
    @apply flex flex-col gap-4 mt-[30px];
}

.table_body {
    @apply p-[30px] space-y-4 border-b border-[#E5E5E5] pb-[70px];
}

.table_row {
    @apply flex justify-between;
}

._table_row_text {
    @apply text-[#6F6F6F] font-roboto text-[14px];
}

.table_data_text {
    @apply text-[15px] text-[#6F6F6F] font-roboto font-medium;
}

.total_price {
    @apply text-[20px] font-roboto font-semibold;
}

.total_price_number {
    @apply text-[#585858] text-[20px] font-roboto font-semibold;
}

._checkout_btn {
    @apply text-white font-roboto font-semibold text-[15px] bg-[#A000A0] w-[157px] h-[43px];
}

._sub_total_box {
    @apply p-[30px] flex justify-between items-center;
}