.headingText {
    @apply my-[20px] md:text-[40px] text-[23px] font-bold text-center tracking-wider font-roboto;
}


/* Best Seller Buttton  */

.button {
    @apply  text-lg shadow md:text-[16px]  font-semibold md:w-[212px] w-full h-[37px] bg-[#A000A0] text-white font-roboto;
}
