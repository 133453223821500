.my_account_main {
    @apply flex md:ml-[60px] ml-1 mt-[50px] gap-[54px] border-t border-[#E6E1E1];
}

.my_account_heading {
    @apply text-[19px] text-[#000000] font-roboto font-medium mt-[21px];
}

.my_account_tab_container {
    @apply flex flex-col w-[172px] space-y-3 border-r border-[#E6E1E1] h-full;
}

.my_account_tabBox {
    @apply flex gap-1 items-center w-full h-fit text-[15px] text-[#565656] font-roboto font-medium cursor-pointer;
}