.saved_add_container {
    @apply md:w-[755px] w-[360px] md:h-[287px] rounded border border-[#E3E3E3] md:mt-[25px] mt-[50px] md:p-[30px] p-[10px] shadow shadow-[#E3E3E3] md:ml-0 ml-2;
}

.saved_add_cos_name {
    @apply text-[#000000] md:text-[18px] text-[15px] font-semibold font-roboto;
}

.saved_add_deetails {
    @apply font-roboto text-[#575757] md:text-[15px] text-[13px] font-light
}

.saved_delete_btn {
    @apply text-[#676767] text-[14px] font-roboto font-medium;
}

.edit_saved_add_btn {
    @apply w-[163px] h-[43px] border border-[#000000] text-black font-roboto text-[16px] font-medium text-center rounded-[50px];
}

.edit_btn_add {
    @apply flex justify-end items-center gap-6 md:pt-[30px] pt-[10px];
}

.add_new_add {
    @apply flex justify-center md:pt-[90px] md:mb-0 mb-3;
}