.loginContainer {
    @apply md:pt-[10px] pt-[0px] md:pl-[60px] pl-[0px] md:pr-[60px] pr-[0px] pb-[10px];
}

.loginContainerInside {
    @apply flex justify-center pt-[59px];
}

.loginpageBox {
    @apply md:w-[549px] w-full h-fit flex flex-col md:gap-[15px] gap-[10px];
}

.loginForm {
    @apply flex flex-col items-center md:gap-[10px] gap-[10px];
}

.loginTextBox {
    @apply flex flex-col md:items-start items-center;
}

.loginText {
    @apply font-roboto text-[34px] md:pl-[0px];
}

.welcomeText {
    @apply font-roboto text-[20px] font-light;
}


.emailTextBox {
    @apply flex flex-col gap-[8px];
}

.emailText {
    @apply text-[18px] font-roboto font-light text-[#000000];
}

.Input {
    @apply md:w-[549px] w-[330px] md:h-[50px] rounded-[5px] border border-[#D0D0D0] outline-none p-2;
}

.passwordBox {
    @apply flex flex-col gap-[17px];
}

.passwordTextBox {
    @apply flex flex-col gap-[8px] text-[#000000] md:pl-0 pl-[5px] md:pr-0 pr-[5px];
}

.passwordText {
    @apply text-[18px] font-roboto font-light;
}

.forgetPassword {
    @apply font-roboto text-[14px] flex justify-end font-light;
}

.buttonBox {
    @apply flex flex-col items-center gap-[26px];
}

.loginButton {
    @apply font-roboto text-[#FFFFFF] text-[18px] bg-[#A000A0] md:w-[549px] w-[330px] md:h-[62px] h-[50px] rounded-[100px];
}

.haveAccount {
    @apply flex justify-center;
}

.notAccount {
    @apply font-roboto text-[#8F8F8F] md:text-[16px];
}

.signUp {
    @apply font-roboto md:font-[16px] cursor-pointer;
}
.error{
    color: red;
}