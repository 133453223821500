.nothing_list_wrap {
    @apply flex justify-center items-center md:w-[800px] mt-[50px];
}

.nothing_list_wrap_child {
    @apply flex flex-col justify-center items-center gap-6;
}

.wishlist_btn {
    @apply w-[248px] h-[56px] bg-[#A000A0] text-white font-roboto font-medium text-[18px];
}