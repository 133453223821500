.main_section {
    @apply md:ml-0 ml-1 md:mr-0 mr-1;
}

.headerTextContainer_wrap {
    @apply flex justify-evenly lg:pl-0 pl-1 lg:pr-0 pr-1;
}

.headerTextContainer_inside_wrap {
    @apply flex justify-center items-center lg:text-[16px] text-[10px] gap-1;
}

.headerfilterText {
    @apply font-normal font-roboto text-[#767676];
}


/* discription  */
._dis_Image {
    @apply md:w-[608px] md:h-[629px];
}

._product_dis_container {
    @apply mt-[40px] mb-[40px] flex md:flex-row flex-col md:justify-evenly;
}

._pro_text {
    @apply md:text-[40px] text-[25px] font-roboto font-medium;
}

._pro_price {
    @apply text-[20px] font-roboto font-medium;
}

._pro_dis {
    @apply text-[#646464] md:text-[15px] text-[11px];
}

._pro_title {
    @apply text-[15px] text-[#4E4E4E] text-start font-roboto;
}

._pro_specification {
    @apply text-[13px] text-[#121212];
}

._pro_sepci_box {
    @apply flex justify-between items-center;
}

._pro_sepci_main {
    @apply md:w-[600px] md:mx-0 mx-3  md:mt-0 mt-2 h-fit flex flex-col gap-5;
}

.tbody {
    @apply space-y-1;
}

.table {
    @apply md:w-[500px] w-full flex;
}

._pro_dis_text {
    @apply md:text-[20px] text-[15px] font-roboto mt-[30px];
}

._quantity_text {
    @apply text-[13px] font-roboto font-semibold;
}

._quantity_btn_box {
    @apply border border-[#BBBBBB] w-fit p-1 mt-2 items-center flex;
}

._add_to_cart_btn {
    @apply w-[300px] h-[40px] bg-[#A000A0] flex items-center justify-center text-[#FFFFFF] font-roboto font-medium mt-[30px];
}

._sold_by {
    @apply text-[17px] font-roboto font-medium;
}

._seller_name {
    @apply text-[17px] text-[#61B9EB] font-roboto font-medium;
}

.lable_text {
    @apply text-[15px] font-roboto font-semibold;
}

._input_text {
    @apply border-b border-black text-[#515151] outline-none ml-3;
}

._Add_change {
    @apply text-[15px] ml-4 text-[#FF0000] font-roboto font-semibold;
}

._ship_deetails {
    @apply flex;
}

._delivery_Text {
    @apply text-[15px] font-roboto font-medium flex gap-5;
}

._delivery_deetails_box {
    @apply flex flex-col gap-4 mt-[30px];
}

/* Acordian css */
._acordian_wrap {
    @apply flex justify-center items-center md:mb-[100px] mb-[50px];
}

._acordian {
    @apply w-full md:ml-[100px] md:mr-[100px] h-fit border border-[#D4D4D4] rounded p-[30px];
}

._acordian_box {
    @apply flex items-center justify-between pl-4 pr-4 p-2 cursor-pointer;
}

.acordian_heading {
    @apply text-[15px] font-roboto font-bold;
}

._acordian_content {
    @apply text-[13px] text-[#676767] font-roboto pl-4 pr-4 p-1 border-b border-[#EDEDED];
}


/* recently view */

._Product_Card_Container {
    @apply flex border-b-2 border-[#DDDDDD] mt-[30px] gap-5 mb-5;
}

.Product_Container_wrap {
    @apply md:w-[291px] w-[180px] md:h-fit md:rounded-xl rounded-none overflow-hidden shadow-md;
}

.Product_Container {
    @apply relative;
}

.Product_Image {
    @apply md:w-[291px] md:h-[387px] h-fit;
}

.Product_Text_Wrap {
    @apply md:p-4 p-1;
}

._Product_Title {
    @apply md:text-[16px] text-[10px] font-semibold font-roboto text-black;
}

._Product_Description {
    @apply md:text-[14px] text-[8px] font-roboto;
}

._ProductPrice {
    @apply md:text-[16px] text-[9px] font-semibold font-roboto;
}

._ProductPrice2 {
    @apply md:text-[13px] text-[9px] text-[#828282] font-normal line-through font-roboto;
}

._ProductPrice3 {
    @apply text-[#528945] md:text-[13px] text-[9px] font-roboto;
}

.Product_Review_Box {
    @apply flex gap-1 md:pr-[80px] pr-0;
}

._ProductReview {
    @apply text-[#515151] md:text-[12px] text-[8px] font-medium;
}

.starEntity {
    @apply pt-[2px] md:text-[10px] text-[8px];
}

.productReviewInNumber {
    @apply pt-[2px] text-[#7F7272] md:text-[10px] text-[8px];
}