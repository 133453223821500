.Navbar {
    @apply md:pl-[60px] md:pr-[60px] md:mt-[40px] cursor-pointer;
}

.Navbar_inside {
    @apply bg-[#A000A0]/70 rounded-xl p-1.5 md:block hidden duration-1000 relative;
}

.nabar_inside_child {
    @apply flex justify-between p-2 rounded;
}

.navbar_inside_child2 {
    @apply flex gap-[90px] items-center;
}

/* all categories */

.allCategories_box {
    @apply text-white brightness-200 cursor-pointer;
}

.allCategories_box_inside {
    @apply relative flex items-center font-roboto;
}

.dropdown_functionality {
    @apply absolute bg-black/70 rounded w-[200px] p-2 text-start pl-[20px] top-[50px] cursor-pointer;
}

.dropdown_functionality_language {
    @apply absolute bg-black/70 rounded w-[200px] p-2 text-start pl-[20px] top-[50px] right-0 cursor-pointer;
}

.li_links_dropdown {
    @apply text-white text-[15px] font-roboto cursor-pointer;
}

.deals_dropdown_box {
    @apply text-white brightness-200 duration-500 relative cursor-pointer;
}

.midlogo_navbar {
    @apply text-white brightness-200 w-[50px] cursor-pointer;
}

.navbar_inside_2 {
    @apply flex gap-[90px] items-center cursor-pointer;
}

.navbar_cart {
    @apply text-white font-roboto cursor-pointer;
}

.wallet_navbar {
    @apply flex gap-1 items-center text-white font-roboto cursor-pointer;
}

.hamburgerLinks_myaccount {
    @apply flex items-center gap-1 text-[15px] font-roboto cursor-pointer;
}

.hamburgerLinks_myaccount_box {
    @apply md:hidden z-10 text-[#A000A0] text-2xl absolute right-[10px] top-2 cursor-pointer;
}