.od_deetail_wrap {
    @apply bg-[#F7F7F7] w-full h-screen;
}

.order_deetails_wrap {
    @apply w-[894px] h-[369px] bg-[#FFFFFF] ml-[70px] mt-[30px] p-3;
}

.return_btn {
    @apply bg-[#A000A0] text-[14px] font-roboto font-medium w-[202px] h-[43px] text-white;
}

.delivery_deetail_wrap {
    @apply space-y-10 border-b border-[#E5E5E5];
}

.delivery_deetail_wrap_child {
    @apply flex justify-between items-center;
}

.span_delivery_text {
    @apply text-[#878787] font-roboto font-medium text-[13px];
}

.product_order_details {
    @apply mt-[40px] flex items-center gap-[50px] pl-5;
}

.product_name {
    @apply text-[14px] text-[#343434] font-roboto font-medium;
}

.price_text {
    @apply text-[16px] font-roboto text-black ml-1;
}

/* product price deetails */
.od_price_container_wrap {
    @apply w-[356px] h-[107px] bg-[#ECE6DE] mt-[30px] p-4 space-y-1.5;
}

.od_price_container_wrap_child {
    @apply w-[356px] h-[262px] bg-[#FAFAFA] p-4 border border-[#DEDEDE];

}

.od_price_container_wrap_child2 {
    @apply w-[356px] h-[262px] bg-[#FFFFFF] mt-[30px] p-4 border border-[#464646];
}

.od_deetail_heading {
    @apply text-[17px] font-roboto font-medium pb-2 border-b border-[#DEDEDE];
}

.od_data_text {
    @apply text-[13px] text-[#464646] font-roboto font-medium;
}

.od_price_text {
    @apply text-[#CD9D5D] text-[13px] font-roboto font-medium;
}

.od_span_delivery_text {
    @apply font-roboto font-medium border border-[#000000] rounded ml-8 text-[11px] p-[5px] w-[54px] h-[24px];
}

.od_add_text {
    @apply text-[13px] text-[#464646] font-roboto font-medium mt-[20px];
}

.phone_txt {
    @apply text-[#464646] text-[16px] mt-[15px] font-roboto font-semibold;
}