/* ProductCard.css */

.product-card {
    @apply flex flex-col items-center relative;
}

.product-image {
    @apply md:w-[200px] w-[100px] h-auto md:mb-8 mb-[5px];
}

.product-info {
    text-align: center;
}

.product-name {
    @apply md:text-[20px] font-roboto;
}

.product-price {
    color: #888;
}

.remove-button {
    @apply absolute md:top-0 top-1 md:right-0 right-2 text-[#A000A0];
}

.wislist_heading {
    @apply md:text-[36px] text-[25px] font-roboto font-medium text-center md:mt-3 mt-[50px] md:mb-3 mb-[10px];
}

.wishlist_main_wrap {
    @apply grid md:grid-cols-4 grid-cols-3 gap-4;
}