._filter_page {
  @apply md:ml-0 ml-2 md:mr-0 mr-2;
}

.main_Container {
  @apply flex;
}



.headerTextContainer_wrap {
  @apply flex justify-evenly md:mt-[33px] mt-[50px] lg:pl-0 pl-1 lg:pr-0 pr-1;
}

.headerTextContainer_inside_wrap {
  @apply flex justify-center items-center lg:text-[16px] text-[10px] gap-1;
}

.headerfilterText {
  @apply font-normal font-roboto text-[#767676];
}

.headerContainer {
  @apply flex md:flex-row flex-col md:justify-center items-center mt-[32px];
}

.headerInsideContainer {
  @apply flex bg-[#F0E7E3] md:h-[346.5px] h-full md:w-[756px] w-full pt-[32px];
}

.headerTextInsideContainer {
  @apply md:pl-[91px] pl-[10px] md:pr-[155px] pr-[10px] flex flex-col gap-[37px] md:mb-[0px] mb-[50px];
}

.headerTextInsideContainer_wrap {
  @apply flex flex-col items-start justify-center gap-[6px];
}

.headerTextInside {
  @apply md:text-[46px] text-[33px] font-roboto;
}

.headerText_paragraph {
  @apply md:text-[14px] text-[11px] font-roboto text-[#464646];
}

.headerImage {
  @apply md:w-[756px] w-full h-full;
}

/* filter by range */
.filterbyRangeContainer {
  @apply flex md:justify-center items-center gap-[14px] md:mt-[38px] mt-[28px] p-1;
}

.filterText {
  @apply md:text-[24px] text-[12px] font-roboto font-normal;
}

.filter_h1_text {
  @apply md:w-[121px] w-[80px] h-[32px] bg-[#A000A0] rounded-[50px] text-center text-white md:text-[13px] text-[8px] flex justify-center items-center;
}

/* sidebar filter   */

.__Categories_Text {
  @apply text-[17px];
}

.siderFilterContainer {
  @apply md:block hidden;
}

.siderFilterContainer_mobile {
  @apply md:hidden block;
}

.sideFilterInside_container {
  @apply md:w-[296px] w-full h-fit md:mb-0 mb-[20px] border border-[#E5E5E5] rounded-[5px];
}

.sidefilterInside_container_wrap {
  @apply p-2;
}

.sidefilterContainer_frist {
  @apply flex justify-between text-center md:p-3 border-b-2 border-[#E8E8E8];
}

.sidefilterHead_text {
  @apply text-[18px] font-medium font-roboto;
}

.sidefilterContainer_secend {
  @apply mt-[16px] border-b-2 border-[#E8E8E8] md:p-3;
}

.sidefilter_product_text {
  @apply font-roboto text-[15px] font-medium;
}

.product_text_style {
  @apply font-roboto text-[14px] bg-[#F5F5F5] rounded-[50px] md:w-[61px] w-[40px] md:h-[30px] text-center flex justify-center items-center;
}

.sidefilter_product_container {
  @apply flex flex-col md:gap-[8px];
}

.__product_container_ {
  @apply flex justify-between items-center p-3;
}

.___product_dropduwn_wrap {
  @apply flex flex-col md:gap-[10px] gap-0;
}

.section_Box {
  @apply flex justify-between items-center gap-1 mt-[1px] md:pl-[18px];
}

._section_input_box {
  @apply flex items-center;
}

._section_input {
  @apply w-[15px] h-[12px];
}

.__section_product_name {
  @apply text-[15px] font-roboto pl-1;
}

.___section_product_quantity {
  @apply text-[#B0B0B0] text-[10px] font-roboto font-medium;
}

.apply_button_container {
  @apply flex justify-between items-center ml-2 mr-2;
}

._apply_button {
  @apply bg-[#A000A0] w-[158px] h-[36px] text-[14px] font-medium text-white text-center font-roboto rounded-[100px];
}

._found_products {
  @apply text-[13px] font-fair font-medium;
}

._found_products-text {
  @apply text-[14px] font-roboto font-semibold;
}



/* Product Card  */

._Product_Card_Container_dis_ {
  @apply md:flex hidden flex-wrap h-fit w-fit py-3 gap-[22px] ml-[100px] mr-[100px] mt-[50px] mb-[50px];
}

.Product_Container_wrap-filter {
  @apply md:w-[291px] w-[120px] md:h-[380px] h-[100px] flex flex-col rounded-tl-xl rounded-tr-xl overflow-hidden shadow-md;
}

.Product_Container {
  @apply relative;
}

.Product_Image {
  @apply w-full h-full;
}

.Product_Text_Wrap {
  @apply md:p-4;
}

._Product_Title {
  @apply md:text-[16px] text-[13px] md:text-left overflow-hidden text-ellipsis whitespace-nowrap font-semibold font-roboto text-black;
}

._Product_Description {
  @apply md:text-[14px] text-[13px] font-roboto;
}

._ProductPrice {
  @apply md:text-[16px] text-[14px] font-semibold font-roboto;
}

._ProductPrice2 {
  @apply text-[13px] text-[#828282] font-normal line-through font-roboto;
}

._ProductPrice3 {
  @apply text-[#528945] text-[13px] font-roboto;
}

.Product_Review_Box {
  @apply flex gap-1 pr-[80px];
}

._ProductReview {
  @apply text-[#515151] text-[12px] font-medium;
}

.starEntity {
  @apply pt-[2px] text-[10px];
}

.productReviewInNumber {
  @apply pt-[2px] text-[#7F7272] text-[10px];
}

/* show more  */
._show_more {
  @apply flex justify-center text-[#595454] font-roboto;
}

._show_more_btn {
  @apply w-[143px] h-[43px] bg-[#EFEFEf] mb-[30px] mt-[10px] rounded-[100px];
}