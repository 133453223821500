.main_wrap {
    @apply mt-0;
}

.pay_main_wrap {
    @apply flex flex-col gap-[50px];
}

.main_child_wrap {
    @apply flex justify-center;
}

.child_wrap {
    @apply w-[1450px] h-fit bg-[#ECECEC] md:p-4 md:mt-[30px] mt-[50px];
}

.checkout_heading {
    @apply md:text-[26px] font-roboto font-bold md:pl-[100px] md:mt-[20px];
}

.boxes_main_wrap {
    @apply md:flex-row flex-col justify-evenly;
}

.child_box_frist {
    @apply md:w-[754px] w-full h-fit bg-[#FFFFFF] md:p-5 mt-[10px] rounded;
}

.line_horizental {
    @apply w-full h-[2px] bg-[#DBDBDB] mt-[36px];
}

/* total payable box */

.total_Payable_main {
    @apply w-[373px] h-fit bg-[#FFFFFF] rounded mt-[10px];
}

.table_body {
    @apply p-[30px] space-y-4 border-b border-[#E5E5E5] pb-[70px];
}

.table_row {
    @apply flex justify-between;
}

._table_row_text {
    @apply text-[#6F6F6F] font-roboto text-[14px];
}

._sub_total_box {
    @apply p-[30px] flex justify-between items-center;
}

.total_price {
    @apply text-[20px] font-roboto font-semibold;
}

.total_price_number {
    @apply text-[#585858] text-[20px] font-roboto font-semibold;
}

.delivery_add_wrap {
    @apply flex gap-2 items-center;
}

.delivery_text {
    @apply text-[19px] font-roboto font-medium;
}

.deli_main_wrap {
    @apply flex justify-between items-center;
}

.cng_btn {
    @apply text-[#A000A0] text-[17px] font-roboto font-medium;
}

.final_add {
    @apply text-[16px] text-[#000000] font-roboto font-medium mt-[30px];
}

.final_add_span {
    @apply text-[#525252] text-[14px] font-roboto font-medium pl-2;
}

/* Pyment box */
.pay_box {
    @apply md:w-[754px] h-fit bg-[#FFFFFF] p-5 rounded;
}

.pay_heading {
    @apply text-[19px] font-roboto font-semibold;
}

.Pay_accordian_box {
    @apply flex items-center justify-between mt-[10px];
}

._accord_text_ {
    @apply text-[#474747] font-roboto font-medium text-[15px];
}

.card_icon {
    @apply flex gap-3 items-center;
}

.line_hori {
    @apply w-full h-[1px] bg-[#ECECEC] mt-[10px];
}

/* credit card form */
.credit_card_form {
    @apply md:flex-row flex-col gap-1;
}

.cardNumber {
    @apply w-[200px] outline-none border border-[#ECECEC] text-[14px] p-1 font-roboto;
}