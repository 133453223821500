/* .header_container {
    @apply max-w-[1550px] mx-auto w-full md:h-[700px] h-[200px] m-auto relative group:;
} */

.header_text_container {
    @apply flex md:flex-row flex-col justify-start md:pl-[150px] md:mt-[100px] mt-[30px] gap-3;
}

.header_text-container_2 {
    @apply flex flex-col text-white text-start md:p-10 p-8;
}

.header_textP {
    @apply font-semibold md:text-6xl text-xl break-after-column md:my-3 my-0;
}

.uptoTextContainer {
    @apply md:pt-4 pt-2 flex flex-col md:gap-5 gap-1;
}

.upto {
    @apply flex md:gap-3 gap-1;
}



.upti_h2 {
    @apply md:space-y-5 space-y-0 md:pt-3 pt-[8px] md:text-xl text-[10px];
}

.upti_h1 {
    @apply md:space-y-5 space-y-0 md:text-3xl text-[13px];
}

.divtext {
    @apply md:text-[18px] text-[9px] font-thin;
}

/* Aerrow button */
.leftbutton {
    @apply hidden group-hover:block absolute md:top-[50%] top-[40%] -translate-x-0 translate-y-[50%] md:left-20 left-3 text-2xl rounded-full p-2 bg-black/40 text-white cursor-pointer;
}

.rightbutton {

    @apply hidden group-hover:block absolute md:top-[50%] top-[40%] -translate-x-0 translate-y-[50%] md:right-20 right-3 text-2xl rounded-full p-2 bg-black/40 text-white cursor-pointer;
}

.lineContainer {
    @apply md:pl-[190px] md:pr-[100px] md:pt-[70px] md:block hidden;
}

.lineContainerInside {
    @apply flex justify-between gap-[20px] text-white;
}

.lineText {
    @apply md:text-[18px] text-[12px] text-[#FFFFFF] py-2 font-roboto;
}

.line {
    @apply w-full h-[1.5px] bg-[#CECECE] flex justify-between;
}