.main_new_add {
    @apply mt-[55px] ml-[400px];
}

.new_main_container {
    @apply flex flex-col items-center justify-center gap-[28px];
}

.location_img_new {
    @apply w-[134px] h-[134px];
}

.new_add_text {
    @apply text-[#878787] text-[20px];
}

.add_new_add_btn {
    @apply w-[202px] h-[43px] bg-[#A000A0] text-[#FFFFFF] text-[17px] font-medium;
}