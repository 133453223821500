/* In your existing Tailwind CSS file or a separate CSS file */

.custom-scrollbar::-webkit-scrollbar {
    width: 4px;
    /* Adjust the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #A000A0;
    border-radius: 6px;

    /* Change the color of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: gray;
    border-radius: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* Change the color of the scrollbar track */
}



._main_wrap {
    @apply flex justify-center items-center;
}

.cart_wrap {
    @apply w-full md:ml-[60px] md:mr-[60px] h-fit pb-10 md:mb-[100px] mb-[50px] mt-4 bg-[#ECECEC] md:pl-[50px] p-2 md:pr-[50px];
}

._cart_heading {
    @apply md:text-[30px] text-[20px] font-roboto font-bold md:pl-[60px] pl-2 mt-[40px];
}

._cart_text {
    @apply text-[#6E6E6E] md:text-[18px] text-[17px] font-roboto md:mt-[25px] mt-[10px] md:pl-0 pl-2;
}

._cart_wrap_child {
    @apply md:min-w-[554px] md:max-w-[650px] md:h-[231px] bg-[#FFFFFF] mt-[30px] rounded p-7 grid grid-flow-col gap-[31px];
}

._cart_image {
    @apply md:w-[159px] md:h-[123px];
}

._Cart_child_text {
    @apply flex flex-col md:gap-2;
}

._child_text_dis {
    @apply md:text-[18px] text-[15px] font-roboto font-medium text-black outline-none;
}

._remove_item {
    @apply text-[#A000A0];
}

._child_Pro_name {
    @apply flex gap-[40px];
}

._quantity_wrap {
    @apply mt-[20px] flex items-center justify-between md:gap-[130px];
}

.wishlist {
    @apply flex gap-2;
}


._cart_main_wrap {
    @apply flex md:flex-row flex-col justify-between md:ml-0 ml-2 md:mr-0 mr-2;
}

._pop_up_btn {
    @apply bg-[#FFFFFF] md:w-[373px] w-full md:h-[56px] py-2 px-4 rounded md:text-[18px] text-[15px] font-roboto font-medium md:ml-0 ml-2 md:mr-0 mr-2;
}

/* screen pop up */

._pop_up_main {
    @apply bg-gray-100 flex items-center justify-center;
}


._offer_input {
    @apply md:w-[444px] h-[35px] border border-[#CDCDCD] outline-none p-2;
}

._coupons_text {
    @apply text-[#000000] font-medium text-[15px] font-roboto mt-[20px];
}

.coupons_header {
    @apply flex justify-between mt-[20px];
}

.coup_no {
    @apply text-[13px] w-[118px] h-[26px] font-roboto font-medium text-[#373737] bg-[#FFF2FF] text-center flex items-center justify-center border-2 border-[#A000A0] rounded;
}

.coup_apply {
    @apply font-roboto font-medium text-[#A000A0] text-center;
}

/* saved price */
._saved_price {
    @apply text-[#3BA914] text-[13px] font-roboto font-medium mt-[10px];
}

._saved_price_span {
    @apply text-[#338715] text-[14px] font-roboto font-bold pl-2;
}


.coupons_deetails_box {
    @apply border-b border-[#DADADA] pb-3;
}

/* discount text */
._discout_text {
    @apply text-[#686868] text-[14px] font-roboto font-normal;
}

._offer_valid_box {
    @apply flex justify-between items-center mt-[10px];
}

.vallid_span {
    @apply text-[#363636] text-[14px] font-roboto font-bold pl-2;
}

/* secendry box */
.sub_price_main {
    @apply md:w-[373px] md:h-[324px] bg-[#FFFFFF] rounded md:ml-0 ml-2 md:mr-0 mr-2;
}

.cart_wrap_child_two {
    @apply flex flex-col gap-4 mt-[30px];
}

.table_body {
    @apply p-[30px] space-y-4 border-b border-[#E5E5E5] pb-[70px];
}

.table_row {
    @apply flex justify-between;
}

._table_row_text {
    @apply text-[#6F6F6F] font-roboto text-[14px];
}

.table_data_text {
    @apply text-[15px] text-[#6F6F6F] font-roboto font-medium;
}

.total_price {
    @apply text-[20px] font-roboto font-semibold;
}

.total_price_number {
    @apply text-[#585858] text-[20px] font-roboto font-semibold;
}

._checkout_btn {
    @apply text-white font-roboto font-semibold text-[15px] bg-[#A000A0] w-[157px] h-[43px];
}

._sub_total_box {
    @apply p-[30px] flex justify-between items-center;
}

/* nested popup  */
.main_hurray_box {
    @apply flex flex-col items-center;
}

.hurray_text {
    @apply text-[23px] font-roboto font-medium mt-[10px] mb-[10px];
}

.hurray_image {
    @apply w-[147px] h-[110px];
}

._applied_text {
    @apply text-[#454545] text-[20px] font-roboto font-medium mt-[10px];
}

._hurray_close_btn {
    @apply w-[294px] h-[33px] border-2 border-[#A000A0] rounded mt-[20px] text-[#A000A0] text-[15px] font-roboto font-bold;
}


._save_btn-cart {
    @apply px-7 py-1.5 bg-[#551e55] text-white text-[15px] p-1;
}