.or_re_main_wrap {
    @apply flex md:flex flex-col justify-center md:mt-0 mt-[80px];
}

.or_re_select_box {
    @apply w-[224px] h-[44px] md:ml-0 ml-[70px] outline-none border border-[#000000] mt-2;
}

.od_rt_box {
    @apply mt-[70px];
}

.continue_btn {
    @apply text-[18px] md:mb-0 mb-[50px] font-roboto font-medium w-[248px] h-[56px] bg-[#A000A0] text-white;
}

.last_month {
    @apply flex flex-col justify-center items-center md:ml-[60px] gap-[20px];

}

.od_rt_deetails {
    @apply md:w-[967px] w-[360px] md:h-[165px] h-[100px] border border-[#D2D2D2] flex gap-5 items-center pl-[10px];
}

.od_rt_product {
    @apply md:w-[151px] w-[100px] md:h-[151px] h-[70px];
}

.od_rt_delivery_text {
    @apply text-[18px] font-roboto font-semibold text-black;
}

.delivery_date {
    @apply text-[13px] font-medium font-roboto;
}

.order_id {
    @apply text-[12px] font-roboto font-medium;
}