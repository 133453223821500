.profile_card {
    @apply bg-[#FFF2FF] md:w-[723px] h-[183px] md:mt-[30px] mt-[100px] flex items-center md:pl-[30px] pl-[10px] md:gap-[40px] gap-[60px] relative;
}

.overview_deetails_box_wrap {
    @apply grid grid-cols-3 md:gap-[30px] gap-[10px] mt-[50px] md:pl-0 pl-[10px];
    ;
}

.overview_box {
    @apply md:w-[220px] md:p-0 p-1 w-[100px] md:h-[218px] h-[100px] border border-[#E6E6E6] flex flex-col items-center justify-center md:gap-2 gap-1;
}

.logout_btn {
    @apply w-[250px] h-[45px] md:ml-0 ml-[50px] bg-[#FF1818] text-white text-[15px] font-roboto font-semibold mt-[40px];
}

.overview_pro_pic {
    @apply w-[133px] h-[132px];
}

.costumer_name {
    @apply text-[16px] font-roboto font-bold;
}

.costumer_deetail_wrap {
    @apply space-y-2;
}

.edit_pro_btn {
    @apply absolute top-3 right-3 border border-[#898989] pl-[20px] pr-[20px] p-1 font-roboto font-thin text-[10px] text-black;
}

.overview_ordr_text {
    @apply text-black font-roboto font-semibold md:text-[17px] text-[15px];
}

.overview_p_text {
    @apply md:text-[15px] text-[13px];
}