.footer {
    @apply bg-[#551E55] md:h-[387px];
}

.footerInsideContainer {
    @apply flex md:flex-row flex-col justify-center items-center md:gap-[200px] gap-[20px];
}

.footerFristConatiner {
    @apply flex md:gap-[200px] gap-[20px];

}

.footerInsideFristConatiner {
    @apply flex flex-col items-start;
}

.contactAndLogo {
    @apply flex flex-col items-start text-start gap-[27px];
}

.logo {
    @apply w-[80px] h-[60px] mt-[25px];
}

.contactus {
    @apply text-white text-start hover:text-amber-300;
}

.Icons {
    @apply flex flex-col items-start mt-[44px] gap-[18px];
}

.IconsBox {
    @apply text-white flex gap-1;
}

.IconBoxThird {
    @apply text-white flex gap-1 pt-[37px];
}

.telefone {
    @apply text-white w-[15px] h-[12px] text-start mt-[5px];
}

.locationIcon {
    @apply text-white w-[17px] h-[15px] text-start mt-[5px];
}

.customText {
    @apply text-[13px] hover:text-amber-300;

}

.footerInsiderSecendContainer {
    @apply flex flex-col items-center mt-[112px];
}

.QuickLinks {
    @apply md:text-[14px] text-[20px] text-white hover:text-amber-300;
}

.mycustomTextParents {
    @apply flex flex-col items-start mt-[44px] gap-[18px] text-white;
}

.footerSecendContainer {
    @apply flex md:gap-[200px] gap-[20px];
}

.footerSecendInsideFristContainner {
    @apply flex flex-col md:items-center mt-[112px];
}

.letsHelpYou {
    @apply md:text-[14px] text-[20px] text-white hover:text-amber-300;
}

.followUs {
    @apply uppercase md:text-[14px] text-[20px] text-white hover:text-amber-300;
}

.footerSecendInsideicons {
    @apply text-white w-[15px] h-[12px] text-start mt-[5px];
}

.footerbottom {
    @apply md:pl-[200px] pl-[10px] md:pr-[200px] pr-[10px] py-6;
}

.footerbottominside {
    @apply w-full md:h-[2.5px] h-[1.5px] bg-white flex justify-between;
}

.allRightes {
    @apply md:text-[12px] text-[8px] text-[#FFFFFF] py-2;
}

.privacyContainer {
    @apply flex gap-[20px];
}

.privacyText {
    @apply md:text-[12px] text-[8px] text-[#FFFFFF] py-2;
}